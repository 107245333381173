import { VALIDATIONS } from '@utils/validations'
import { MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { rejectionReasons } from '@cvg/content-review-localizations'

const { required } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Comment',
    captionShort: 'Comment',
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.text },
          validations: [required],
          validFlags: [
            rejectionReasons.childEndangerment,
            rejectionReasons.inappropriateContent,
            rejectionReasons.lastNames,
            rejectionReasons.personalContactInformation,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.socialMediaInformation
          ]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps }
