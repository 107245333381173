export default function stepsReducer(state, action) {
  switch (action.type) {
    // Flag individual content items for unacceptable content
    case 'FLAG_CONTENT': {
      let { contentId, flags } = action
      let stepToUpdate = state.steps.find(step => step.number === action.step.number)

      let updatedStep = {
        ...stepToUpdate,
        contentCards: stepToUpdate.contentCards.map(card =>
          card.map(cardContent => ({
            ...cardContent,
            contentItems: cardContent.contentItems.map(item =>
              item.id === contentId ? { ...item, approved: false, flags } : item
            )
          }))
        )
      }

      return {
        ...state,
        steps: state.steps.map(step => (step.number === updatedStep.number ? updatedStep : step))
      }
    }

    // Initializes the steps moderator will take to review content
    case 'INITIALIZE_STEPS':
      return {
        ...state,
        steps: action.steps
      }

    // Take user to the next step after marking all unflagged content as approved
    case 'NEXT_STEP': {
      let stepToUpdate = state.steps.find(step => step.number === action.step.number)

      let updatedStep = {
        ...stepToUpdate,
        approved: stepToUpdate.contentCards.flat().every(card => card.contentItems.every(item => !item.flags.length)),
        contentCards: stepToUpdate.contentCards.map(card =>
          card.map(cardContent => ({
            ...cardContent,
            contentItems: cardContent.contentItems.map(item => ({ ...item, approved: !item.flags.length }))
          }))
        )
      }

      return {
        ...state,
        currentStepNumber: state.currentStepNumber + 1,
        steps: state.steps.map(step => (step.number === updatedStep.number ? updatedStep : step))
      }
    }

    // Hide/Show help text related to currently viewed context
    case 'TOGGLE_HELP_TEXT':
      return { ...state, showHelpText: !state.showHelpText }

    // Take user to a previously completed step, and mark every step they backed past as unapproved
    case 'PREVIOUS_STEP':
      return {
        ...state,
        currentStepNumber: action.stepNumber,
        steps: state.steps.map(step => (step.number < action.stepNumber ? step : { ...step, approved: null }))
      }

    // Update moderator comments
    case 'UPDATE_COMMENT': {
      return { ...state, comment: action.event.target.value }
    }

    // Update content items 'data' key with updated data the moderator corrected/changed
    case 'UPDATE_DATA': {
      const { target } = action.event
      const { name, value } = target

      let stepToUpdate = state.steps.find(step => step.number === action.step.number)

      let updatedStep = {
        ...stepToUpdate,
        contentCards: stepToUpdate.contentCards.map(card =>
          card.map(cardContent => ({
            ...cardContent,
            contentItems: cardContent.contentItems.map(item => (item.id === name ? { ...item, data: value } : item))
          }))
        )
      }

      return {
        ...state,
        steps: state.steps.map(step => (step.number === updatedStep.number ? updatedStep : step))
      }
    }

    // Update content items 'translation.target.data' key with updated data the moderator corrected/changed
    case 'UPDATE_TRANSLATION': {
      const { target } = action.event
      const { name, value } = target

      let stepToUpdate = state.steps.find(step => step.number === action.step.number)

      let updatedStep = {
        ...stepToUpdate,
        contentCards: stepToUpdate.contentCards.map(card =>
          card.map(cardContent => ({
            ...cardContent,
            contentItems: cardContent.contentItems.map(item =>
              item.id === name
                ? {
                    ...item,
                    translation: { ...item.translation, target: { ...item.translation.target, data: value } }
                  }
                : item
            )
          }))
        )
      }

      return {
        ...state,
        steps: state.steps.map(step => (step.number === updatedStep.number ? updatedStep : step))
      }
    }

    // Add all failed validations to the errors array
    case 'VALIDATE_CONTENT': {
      const { errors, step } = action
      const updatedStep = { ...step, errors }

      return {
        ...state,
        steps: state.steps.map(step => (step.number === updatedStep.number ? updatedStep : step))
      }
    }
  }
}
