import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useLanguageContext } from '@cvg/react-hooks'

import Settings from '@components/Settings'

import styles from './styles.module.css'

const Header = () => {
  const { pathname } = useLocation()
  const { getTranslation } = useLanguageContext()

  return (
    <header className={styles.header} data-url={pathname}>
      <Link aria-label={getTranslation('Home')} to='/' />
      <h1>{getTranslation('Content Review')}</h1>
      <Settings />
    </header>
  )
}

export default Header
