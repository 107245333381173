import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import { getTranslationLanguages } from '@utils/localization'

import Translation from './Translation'
import InputType from './InputType'

import styles from './styles.module.css'

const Text = ({ content }) => {
  const { currentStep, updateData, updateTranslation } = useCustomContext()

  const { getTranslation } = useLanguageContext()
  const languages = getTranslationLanguages(content)

  if (languages?.source !== languages?.target) {
    return <Translation content={content} languages={languages} />
  }

  return (
    <div className={styles.text}>
      <h3>{getTranslation(content.label)}</h3>
      <ul>
        {content.contentItems.map(item => (
          <li key={item.id}>
            <InputType
              content={item}
              name={item.id}
              onChange={event => (item.translation ? updateTranslation(event, currentStep) : updateData(event, currentStep))}
              value={item.translation ? item.translation.target.data : item.data}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Text
