import React from 'react'

import { useCustomContext, useLanguageContext, useSubmit } from '@cvg/react-hooks'
import { Input, showError } from '@cvg/react-components'

import { useSWRConfig } from 'swr'

import { updateCurrentUser } from '@services/user'
import { CACHE_KEYS, getOptimisticUIUser, updateUserCaches } from '@utils/swr'

import styles from '../styles.module.css'

const Notifications = () => {
  const { cache } = useSWRConfig()
  const { getTranslation } = useLanguageContext()
  const { currentUser } = useCustomContext()

  const { submit, isSubmitting } = useSubmit(updateCurrentUser, {
    cacheKey: CACHE_KEYS.user,
    onError: () => showError(getTranslation('ERROR_MESSAGE')),
    onSuccess: updatedUser => updateUserCaches(updatedUser, currentUser, cache.get(CACHE_KEYS.users)?.data),
    swrOptions: {
      optimisticData: previousUser =>
        getOptimisticUIUser(previousUser, { notificationsEnabled: !previousUser.notificationsEnabled })
    }
  })

  return (
    <div className={styles.setting}>
      <div>
        <label>{getTranslation('Email Notifications')}</label>
        <p>{getTranslation('Receive daily emails for pending reviews')}.</p>
      </div>
      <Input
        disabled={isSubmitting}
        name='notificationsEnabled'
        onClick={() => submit({ notificationsEnabled: !currentUser.notificationsEnabled })}
        type='toggle'
        value={currentUser.notificationsEnabled}
      />
    </div>
  )
}

export default Notifications
