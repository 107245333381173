import { VALIDATIONS } from '@utils/validations'
import { STEP_ACTIONS } from '../../constants'

const { required } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Translate Church Vision Statement',
    captionShort: 'Vision Statement',
    contentCardsConfig: [
      [
        {
          label: 'Church Vision Statement',
          contentFilters: { role: 'churchVisionStatement-visionStatement' },
          validFlags: [],
          validations: [required]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps, helpTextSource: import.meta.env.PUBLIC_N2N_FOUNDATIONS_LOCALIZATION_HELP_TEXT_SOURCE }
