import { VALIDATIONS } from '@utils/validations'
import { MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { rejectionReasons } from '@cvg/content-review-localizations'

const { required, transcription } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Post',
    captionShort: 'Post',
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.image },
          validFlags: [
            rejectionReasons.childEndangerment,
            rejectionReasons.inappropriateClothingForTheCulture,
            rejectionReasons.inappropriatePhotoContentOrBackground,
            rejectionReasons.lastNames,
            rejectionReasons.personalContactInformation,
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.socialMediaInformation
          ]
        }
      ],
      [
        {
          label: 'Description',
          contentFilters: { role: 'description' },
          validations: [required],
          validFlags: [
            rejectionReasons.captionDoesNotMatchImage,
            rejectionReasons.childEndangerment,
            rejectionReasons.inappropriateContent,
            rejectionReasons.lastNames,
            rejectionReasons.personalContactInformation,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.socialMediaInformation
          ]
        }
      ],
      [
        {
          label: 'Category',
          contentFilters: { role: 'category' },
          validFlags: [rejectionReasons.categoryDoesNotMatchContent]
        }
      ],
      [
        {
          label: 'Impact',
          contentFilters: { role: 'impact' },
          validFlags: [
            rejectionReasons.childEndangerment,
            rejectionReasons.inappropriateContent,
            rejectionReasons.lastNames,
            rejectionReasons.needMoreInformation,
            rejectionReasons.personalContactInformation,
            rejectionReasons.poorDescription,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.socialMediaInformation
          ]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Video',
    captionShort: 'Video',
    optional: true,
    contentCardsConfig: [
      [
        {
          label: 'Video',
          contentFilters: { mediaType: MEDIA_TYPES.video },
          validFlags: [
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.inappropriateClothingForTheCulture,
            rejectionReasons.inappropriateVideo,
            rejectionReasons.lastNames,
            rejectionReasons.personalContactInformation,
            rejectionReasons.socialMediaInformation,
            rejectionReasons.poorVideoQuality
          ]
        }
      ],
      [
        {
          label: 'Transcription',
          contentFilters: { mediaType: MEDIA_TYPES.transcription },
          validations: [transcription],
          validFlags: [
            rejectionReasons.inappropriateContent,
            rejectionReasons.lastNames,
            rejectionReasons.personalContactInformation,
            rejectionReasons.socialMediaInformation
          ]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps }
