import { CONTEXT_TYPES, STEP_ACTIONS } from '@utils/constants'
import { E2E_VALUES } from '@e2e/constants'

import cause from './contexts/cause.js'
import causeApproval from './contexts/cause-approval.js'
import causeSupporterLocalization from './contexts/cause-supporter-localization.js'
import causeProgress from './contexts/causeProgress.js'
import causeProgressApproval from './contexts/causeProgress-approval.js'
import clipsTranscription from './contexts/clips-transcription.js'
import clipsTranslation from './contexts/clips-translation.js'
import communityComment from './contexts/communityComment.js'
import communityPost from './contexts/communityPost.js'
import communityProfile from './contexts/communityProfile.js'
import foundations from './contexts/foundations.js'
import foundationsSupporterLocalization from './contexts/foundations-localization.js'

import e2e from './contexts/e2e.js'
import noConfig from './contexts/noConfig.js'

// Definitions of steps and content within each step for a given context
export const STEPS_CONFIG = {
  [CONTEXT_TYPES.cause]: cause,
  [CONTEXT_TYPES.causeApproval]: causeApproval,
  [CONTEXT_TYPES.causeSupporterLocalization]: causeSupporterLocalization,
  [CONTEXT_TYPES.causeProgress]: causeProgress,
  [CONTEXT_TYPES.causeProgressApproval]: causeProgressApproval,
  [CONTEXT_TYPES.clipsTranscription]: clipsTranscription,
  [CONTEXT_TYPES.clipsTranslation]: clipsTranslation,
  [CONTEXT_TYPES.communityComment]: communityComment,
  [CONTEXT_TYPES.communityPost]: communityPost,
  [CONTEXT_TYPES.communityProfile]: communityProfile,
  [CONTEXT_TYPES.foundations]: foundations,
  [CONTEXT_TYPES.foundationsSupporterLocalization]: foundationsSupporterLocalization,
  [E2E_VALUES.context]: e2e
}

//Get helpTextSource if provided in the context
export const getContextHelpTextSource = context => {
  return STEPS_CONFIG[context] ? STEPS_CONFIG[context].helpTextSource : noConfig.helpTextSource
}
// Build steps array to be rendered in components/Steps
export const setupSteps = (context, contentItems) => {
  return (STEPS_CONFIG[context] ? STEPS_CONFIG[context].reviewSteps : noConfig.reviewSteps)
    .map(step => {
      return {
        ...Object.fromEntries(Object.entries(step).filter(([name]) => name !== 'contentCardsConfig')),
        ...(step.action === STEP_ACTIONS.review
          ? {
              approved: null,
              errors: {},
              contentCards: _buildContentCards(contentItems, step.contentCardsConfig)
            }
          : {})
      }
    })
    .filter(step => !step.optional || (step.optional && step.contentCards.length))
    .map((step, idx) => ({ ...step, number: idx + 1 }))
}

// build content cards array to be rendered in components/StepContent
const _buildContentCards = (allContentItems, contentCardsConfig = []) => {
  const cards = []

  contentCardsConfig.forEach(cardConfig => {
    const itemsConfig = cardConfig.flatMap(({ contentFilters, isOptional = false }) => ({
      contentFilters,
      isOptional
    }))

    allContentItems
      .filter(items =>
        itemsConfig.every(
          itemConfig =>
            itemConfig.isOptional ||
            Object.entries(itemConfig.contentFilters).every(([key, value]) =>
              items.some(item => _filterItem(item, key, value))
            )
        )
      )
      .forEach(groupedItems => {
        cards.push(
          cardConfig
            .map(config => ({
              label: config.label,
              contentItems: _filterItems(config.contentFilters, groupedItems).map(item => ({
                data: item.data,
                flags: [],
                id: item.id,
                mediaType: item.mediaType,
                readOnly: !!item.readOnly,
                role: item.role,
                translation: item.translation,
                validFlags: config.validFlags,
                validations: config.validations || []
              })),
              mediaType: config.mediaTypeOverride || _filterItems(config.contentFilters, groupedItems)[0]?.mediaType,
              metadata: _filterItems(config.contentFilters, groupedItems).flatMap(item => item.metadata),
              screening: _filterItems(config.contentFilters, groupedItems).flatMap(item => item.moderationFlags)
            }))
            .filter(item => item.contentItems.length)
        )
      })
  })

  return cards
}

const _filterItems = (filters, items) => {
  return items.filter(item => Object.entries(filters).every(([key, value]) => _filterItem(item, key, value)))
}

const _filterItem = (item, filterKey, filterValue) =>
  filterValue instanceof Function ? filterValue({ value: item[filterKey] }) : item[filterKey] === filterValue
