import React from 'react'
import { Link } from 'react-router-dom'

import ContentPreview from '../ContentPreview'
import Translation from '../Translation'
import Status from './Status'
import SubmittedDate from '@components/SubmittedDate'

import { RECORD_STATUS, RECORD_STATUS_TYPES } from '@utils/constants'

import styles from './styles.module.css'

const PendingRecord = ({ context, record }) => {
  const getRecordStatus = () => {
    for (const status of Object.keys(RECORD_STATUS)) {
      if (record[status]) return status
    }
  }

  const getRecordStatusType = () => {
    if (record.isBeingReviewed) {
      return RECORD_STATUS_TYPES.info
    }
  }

  return (
    <li className={styles.record} data-status-type={getRecordStatusType()}>
      <Link to={record.isBeingReviewed ? '' : `/review/${record.id}`} state={{ context }}>
        <div className={styles.details}>
          <Translation record={record} />
          <SubmittedDate date={record.submittedAt} />
          <ContentPreview record={record} />
          <Status status={getRecordStatus()} type={getRecordStatusType()} />
        </div>

        <div className={styles.thumbnail}>
          <img alt='' src={record.thumbnail} />
        </div>
      </Link>
    </li>
  )
}

export default PendingRecord
