import React from 'react'

import InputType from '../../InputType'

import styles from './styles.module.css'

const TranslationSource = ({ contentItems }) => {
  return (
    <div className={styles.source}>
      {contentItems.map(item => (
        <InputType
          canFlagContent={false}
          content={item}
          key={`readonly_${item.id}`}
          name={`readonly_${item.id}`}
          readOnly
          value={item.translation.source.data}
        />
      ))}
    </div>
  )
}

export default TranslationSource
