import React from 'react'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'
import { Input } from '@cvg/react-components'

import { STEP_ACTIONS } from '@utils/constants'

import Flagged from './Flagged'
import SubmitButton from './SubmitButton'

import styles from './styles.module.css'

const StepSummary = () => {
  const { comment, isSubmitting, steps, submitContent, updateComment } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const approved = steps.every(step => step.action === STEP_ACTIONS.submit || step.approved)

  return (
    <div className={styles['step-summary']}>
      <section>
        <h3>{getTranslation(approved ? 'Summary' : 'Flagged Content Summary')}</h3>

        {approved && <p>{getTranslation('All content was approved.')}</p>}
        {!approved && <Flagged />}
      </section>

      <section>
        <h3>{getTranslation('Additional Comments')}</h3>
        <Input
          name='comment'
          onChange={updateComment}
          placeholder={getTranslation('Enter any additional comments you may have.')}
          type='flexTextarea'
          value={comment}
        />
      </section>

      <SubmitButton disabled={isSubmitting} onSubmit={submitContent} />
    </div>
  )
}

export default StepSummary
