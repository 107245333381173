import { VALIDATIONS } from '@utils/validations'
import { MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { rejectionReasons } from '@cvg/content-review-localizations'

const { required } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Translate Cause Info',
    captionShort: 'Info',
    contentCardsConfig: [
      [
        {
          label: 'Title',
          contentFilters: { role: 'title' },
          validations: [required],
          validFlags: []
        }
      ],
      [
        {
          label: 'Subtitle',
          contentFilters: { role: 'subtitle' },
          validations: [required],
          validFlags: []
        }
      ],
      [
        {
          label: 'Physical Resource Contribution',
          contentFilters: { role: 'physical_resource_contribution' },
          validFlags: []
        }
      ],
      [
        {
          label: 'Human Resource Contribution',
          contentFilters: { role: 'human_resource_contribution' },
          validFlags: []
        }
      ],
      [
        {
          label: 'Other Local Contributions',
          contentFilters: { role: 'nonmonetary_contribution' },
          validFlags: []
        }
      ],
      [
        {
          label: 'Resource Gap Explanation',
          contentFilters: { role: 'resource_gap_explanation' },
          validFlags: []
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Translate Description',
    captionShort: 'Description',
    contentCardsConfig: [
      [
        {
          label: 'Summary',
          contentFilters: { role: 'summary' },
          validFlags: []
        }
      ],
      [
        {
          label: 'Description',
          contentFilters: { role: 'description' },
          validations: [required],
          validFlags: []
        }
      ],
      [
        {
          label: 'Timeline Description',
          contentFilters: { role: 'implementation_timeline_description' },
          validFlags: [rejectionReasons.needMoreInformation]
        }
      ],
      [
        {
          label: 'Cause Community Mapping',
          contentFilters: { role: 'cause_community_mapping' },
          validFlags: []
        }
      ],
      [
        {
          label: 'Need Identification Story',
          contentFilters: { role: 'need_identification_story' },
          validFlags: []
        }
      ],
      [
        {
          label: 'Impact Sustainability Plan',
          contentFilters: { role: 'impact_sustainability_plan' },
          validFlags: []
        }
      ],
      [
        {
          label: 'Cause Holistic Child Development',
          contentFilters: { role: 'cause_holistic_child_development' },
          validFlags: []
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Photos',
    captionShort: 'Photos',
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.image },
          validFlags: []
        },
        {
          label: 'Caption',
          contentFilters: { role: 'caption' },
          isOptional: true,
          validFlags: [],
          validations: [required]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps, helpTextSource: import.meta.env.PUBLIC_N2N_CAUSE_LOCALIZATION_HELP_TEXT_SOURCE }
