import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const Locale = ({ type, locale }) => {
  const { getTranslation } = useLanguageContext()

  return <div className={`${styles.locale} ${styles[type]}`}>{getTranslation(locale)}</div>
}

export default Locale
