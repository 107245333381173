import { VALIDATIONS } from '@utils/validations'
import { MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { rejectionReasons } from '@cvg/content-review-localizations'

const { required, currency } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Cause Info',
    captionShort: 'Info',
    contentCardsConfig: [
      [
        {
          label: 'Title',
          contentFilters: { role: 'title' },
          validations: [required],
          validFlags: [
            rejectionReasons.duplicateCauseRequest,
            rejectionReasons.inappropriateContent,
            rejectionReasons.titleIsUnclear
          ]
        }
      ],
      [
        {
          label: 'Subtitle',
          contentFilters: { role: 'subtitle' },
          validations: [required],
          validFlags: [rejectionReasons.inappropriateContent, rejectionReasons.titleIsUnclear]
        }
      ],
      [
        {
          label: 'Category',
          contentFilters: { role: 'categories' },
          validations: [required],
          validFlags: [rejectionReasons.notAlignedWithCauseRequest, rejectionReasons.fcpAlreadyHasInfrastructureCause]
        }
      ],
      [
        {
          label: 'Subcategory',
          contentFilters: { role: 'subcategory' },
          validations: [],
          validFlags: [rejectionReasons.notAlignedWithCauseRequest]
        }
      ],
      [
        {
          label: 'Currency',
          contentFilters: { role: 'request_currency' },
          validations: [required],
          validFlags: [
            rejectionReasons.currencyIsNotSupported,
            rejectionReasons.currencyLocationIsNotAlignedWithChurchLocation
          ]
        }
      ],
      [
        {
          label: 'Requested Amount',
          contentFilters: { role: 'request_amount' },
          validations: [required, currency],
          validFlags: [rejectionReasons.amountExceedsLimit, rejectionReasons.notAlignedWithCauseRequest]
        }
      ],
      [
        {
          label: 'Local Monetary Contribution',
          contentFilters: { role: 'monetary_contribution' },
          validations: [currency],
          validFlags: [rejectionReasons.insufficientLocalContribution]
        }
      ],
      [
        {
          label: 'Physical Resource Contribution',
          contentFilters: { role: 'physical_resource_contribution' },
          validFlags: [
            rejectionReasons.insufficientLocalContribution,
            rejectionReasons.inappropriateContent,
            rejectionReasons.needMoreInformation
          ]
        }
      ],
      [
        {
          label: 'Human Resource Contribution',
          contentFilters: { role: 'human_resource_contribution' },
          validFlags: [
            rejectionReasons.insufficientLocalContribution,
            rejectionReasons.inappropriateContent,
            rejectionReasons.needMoreInformation
          ]
        }
      ],
      [
        {
          label: 'Other Local Contributions',
          contentFilters: { role: 'nonmonetary_contribution' },
          validFlags: [
            rejectionReasons.insufficientLocalContribution,
            rejectionReasons.inappropriateContent,
            rejectionReasons.needMoreInformation
          ]
        }
      ],
      [
        {
          label: 'Resource Gap Explanation',
          contentFilters: { role: 'resource_gap_explanation' },
          validFlags: [
            rejectionReasons.insufficientLocalContribution,
            rejectionReasons.inappropriateContent,
            rejectionReasons.needMoreInformation
          ]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Description',
    captionShort: 'Description',
    contentCardsConfig: [
      [
        {
          label: 'Summary',
          contentFilters: { role: 'summary' },
          validFlags: [
            rejectionReasons.needMoreInformation,
            rejectionReasons.inappropriateContent,
            rejectionReasons.notAlignedWithCauseRequest
          ]
        }
      ],
      [
        {
          label: 'Description',
          contentFilters: { role: 'description' },
          validations: [required],
          validFlags: [
            rejectionReasons.needMoreInformation,
            rejectionReasons.betterFitForCIV,
            rejectionReasons.childEndangerment,
            rejectionReasons.inappropriateContent,
            rejectionReasons.notAlignedWithCauseRequest,
            rejectionReasons.notAlignedWithCIMission
          ]
        }
      ],
      [
        {
          label: 'Is Urgent',
          contentFilters: { role: 'is_urgent' },
          validations: [required],
          validFlags: [rejectionReasons.notAlignedWithCauseRequest]
        }
      ],
      [
        {
          label: 'Is Seed Funding',
          contentFilters: { role: 'is_seed_funding' },
          validations: [required],
          validFlags: [rejectionReasons.notAlignedWithCauseRequest]
        }
      ],
      [
        {
          label: 'Total Children Impacted',
          contentFilters: { role: 'total_children_impacted' },
          validations: [required],
          validFlags: [rejectionReasons.notAlignedWithCauseRequest]
        }
      ],
      [
        {
          label: 'Child Outcomes',
          contentFilters: { role: 'outcomes' },
          validations: [required],
          validFlags: [rejectionReasons.notAlignedWithCauseRequest]
        }
      ],
      [
        {
          label: 'Timeline Description',
          contentFilters: { role: 'implementation_timeline_description' },
          validFlags: [rejectionReasons.needMoreInformation]
        }
      ],
      [
        {
          label: 'Cause Community Mapping',
          contentFilters: { role: 'cause_community_mapping' },
          validFlags: [
            rejectionReasons.needMoreInformation,
            rejectionReasons.inappropriateContent,
            rejectionReasons.notAlignedWithCauseRequest
          ]
        }
      ],
      [
        {
          label: 'Need Identification Story',
          contentFilters: { role: 'need_identification_story' },
          validFlags: [
            rejectionReasons.needMoreInformation,
            rejectionReasons.inappropriateContent,
            rejectionReasons.notAlignedWithCauseRequest
          ]
        }
      ],
      [
        {
          label: 'Impact Sustainability Plan',
          contentFilters: { role: 'impact_sustainability_plan' },
          validFlags: [
            rejectionReasons.needMoreInformation,
            rejectionReasons.inappropriateContent,
            rejectionReasons.notAlignedWithCauseRequest
          ]
        }
      ],
      [
        {
          label: 'Cause Holistic Child Development',
          contentFilters: { role: 'cause_holistic_child_development' },
          validFlags: [
            rejectionReasons.needMoreInformation,
            rejectionReasons.inappropriateContent,
            rejectionReasons.notAlignedWithCauseRequest,
            rejectionReasons.notAlignedWithCIMission
          ]
        }
      ]
    ]
  },
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review Photos',
    captionShort: 'Photos',
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.image },
          validFlags: [
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.inappropriateContent,
            rejectionReasons.imageDoesNotShowcaseTheCauseRequest
          ]
        },
        {
          label: 'Caption',
          contentFilters: { role: 'caption' },
          isOptional: true,
          validFlags: [rejectionReasons.inappropriateContent, rejectionReasons.captionDoesNotMatchImage],
          validations: [required]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps, helpTextSource: import.meta.env.PUBLIC_N2N_CAUSE_HELP_TEXT_SOURCE }
