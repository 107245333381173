import React from 'react'

import { useLanguageContext } from '@cvg/react-hooks'
import { Loader } from '@cvg/react-components'

import styles from './styles.module.css'

const SubmitButton = ({ disabled, onSubmit, ...rest }) => {
  const { getTranslation } = useLanguageContext()

  const buttonLabel = disabled ? <Loader height='50px' /> : getTranslation('Submit')

  return (
    <button className={styles.submit} disabled={disabled} onClick={onSubmit} {...rest}>
      {buttonLabel}
    </button>
  )
}

export default SubmitButton
