import React from 'react'
import { useSWRConfig } from 'swr'

import { useCustomContext, useLanguageContext } from '@cvg/react-hooks'

import Page from '@components/Page'
import ContextCard from '@components/ContextCard'

import styles from './styles.module.css'

const Home = () => {
  const { isCurrentUserLoading, currentUser, currentUserError } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const { mutate } = useSWRConfig()

  const { contexts } = currentUser

  return (
    <Page className={styles.home}>
      <Page.Header />
      <Page.Main>
        <div className={styles.subheader}>
          <h2>{getTranslation('My Queue')}</h2>
          <button className={styles.refresh} onClick={() => mutate(() => true, undefined)}>
            {getTranslation('Refresh')}
          </button>
        </div>

        <Page.AsyncContent isError={!!currentUserError} isLoading={isCurrentUserLoading}>
          <div className={styles.contexts} data-contexts={contexts.length} data-empty={getTranslation('NO_PERMISSIONS')}>
            {contexts.toSorted().map(context => (
              <ContextCard context={context} key={context} />
            ))}
          </div>
        </Page.AsyncContent>
      </Page.Main>
    </Page>
  )
}

export default Home
