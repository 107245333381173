import { VALIDATIONS } from '@utils/validations'
import { MEDIA_TYPES, STEP_ACTIONS } from '@utils/constants'
import { rejectionReasons } from '@cvg/content-review-localizations'

const { required } = VALIDATIONS

const reviewSteps = [
  {
    action: STEP_ACTIONS.review,
    captionLong: 'Review User Profile',
    captionShort: 'Profile',
    contentCardsConfig: [
      [
        {
          contentFilters: { mediaType: MEDIA_TYPES.image },
          validFlags: [
            rejectionReasons.inappropriateClothingForTheCulture,
            rejectionReasons.inappropriatePhotoContentOrBackground,
            rejectionReasons.personalContactInformation,
            rejectionReasons.photoExcessivelyBlurry,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.socialMediaInformation
          ]
        }
      ],
      [
        {
          label: 'Bio',
          contentFilters: { role: 'bio' },
          validations: [required],
          validFlags: [
            rejectionReasons.inappropriateContent,
            rejectionReasons.personalContactInformation,
            rejectionReasons.requestForSpecialFavorOrMoney,
            rejectionReasons.socialMediaInformation
          ]
        }
      ]
    ]
  },
  { action: STEP_ACTIONS.submit, captionLong: 'Submit', captionShort: 'Submit' }
]

export default { reviewSteps }
