import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'
import { useLanguageContext } from '@cvg/react-hooks'

import styles from './styles.module.css'

const StepNav = () => {
  const { currentStep, goToNextStep, validateContent } = useCustomContext()
  const { getTranslation } = useLanguageContext()

  const next = () => {
    if (validateContent(currentStep)) {
      goToNextStep(currentStep)
    }
  }

  return (
    <div className={styles['step-nav']}>
      <button onClick={next} type='button'>
        {getTranslation('Next')}
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
          <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
        </svg>
      </button>
    </div>
  )
}

export default StepNav
