import React from 'react'

import { useCustomContext } from '@cvg/react-hooks'

import InputType from '../../InputType'

import styles from './styles.module.css'

const TranslationTarget = ({ contentItems }) => {
  const { currentStep, updateTranslation } = useCustomContext()

  return (
    <div className={styles.target}>
      {contentItems.map(item => (
        <InputType
          content={item}
          key={item.id}
          name={item.id}
          onChange={event => updateTranslation(event, currentStep)}
          value={item.translation.target.data}
        />
      ))}
    </div>
  )
}

export default TranslationTarget
